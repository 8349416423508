@import '../../mixins';
@import '../../variables';

.wrapper {
    @include size($width: 100%);
    height: 97vh;
}

.content {
    max-width: 600px;
    @include media-breakpoint-down (sm) {
        padding-top: 60px;
    }
}

.content-wrapper {
    height: 97vh;
}

.title {
    @include font($size: 40px, $weight: 500, $line-height: 48px, $font-style: normal, $color: $conversed-primary);
    @include margin($top: 75px, $bottom: 32px);
    @include media-breakpoint-down(sm) {
        font-size: 28px;
        @include margin($top: 35px, $bottom: 12px);
    }
    align-self: flex-start;
}

.card-container {
    height  : 545px;
    overflow-y: auto;
}

.copyright {
    @include font($size: 12px, $font-style: normal, $weight: 400, $line-height: 14px, $text-align: left);
    @include margin($top: auto, $right: auto, $bottom: 16px, $left: 16px);
    align-self    : flex-end;
    letter-spacing: 0;
}
