@import '../../variables';
@import '../../mixins';

.settings-sidebar-wrapper {
    position  : relative;
    background: $conversed-primary-02;
    @include media-breakpoint-down(sm) {
        position  : absolute;
        background: #fbfcfd;
        height    : calc(100vh - 60px);
        width     : 100%;
        z-index   : 5;
    }

    &::after {
        position  : absolute;
        content   : '';
        top       : 0;
        bottom    : 0;
        right     : 0;
        width     : 1px;
        background: #DEDFE0;
    }

    .sidebar-element {
        width : 320px;
        border: none;
        @include media-breakpoint-down(sm) {
            width: 100%;
        }

        .cv-accordion-body {
            position  : relative;
            padding   : 0 0 0 44px;
            background: white;
            z-index   : 5;
        }
    }

    .collapsed {
        background: red !important;
    }

    .accordion-title {
        &.active {
            button {
                > span {
                    transform : rotate(180deg);
                    transition: ease-in-out .5s;
                }
            }
        }

        button {
            display        : flex;
            justify-content: space-between;
            align-items    : center;
            padding        : 14px 24px;
            color          : $conversed-text;
            background     : $conversed-primary-02;
            box-shadow     : none !important;
            @include hover-state();

            &:active, &:focus, &:hover {
                background: $conversed-primary-05;
                outline   : none;
                box-shadow: none;
                z-index   : 0;
            }

            &::after {
                display: none;

                span {
                    font-size: 24px;
                    color    : $conversed-text;
                }
            }

            .accordion-header-wrapper {
                display        : flex;
                justify-content: flex-start;
                align-items    : center;

                span {
                    font-size   : 18px;
                    margin-right: 8px;
                    color       : $conversed-text;
                }
            }
        }
    }

    .list-wrapper {
        position: relative;
        padding : 0;

        &::before {
            content   : '';
            position  : absolute;
            top       : 0;
            right     : 0;
            left      : -44px;
            height    : 1px;
            background: #DEDFE0;
        }

        &::after {
            content   : '';
            position  : absolute;
            bottom    : 0;
            right     : 0;
            left      : -44px;
            height    : 1px;
            background: #DEDFE0;
        }

        > div {
            display    : flex;
            align-items: center;
            height     : 30px;
            padding    : 0;
            border     : none;
            background : transparent;
            font-size  : 13px;
            font-weight: 400;
            cursor     : pointer;
            color      : $conversed-text;

            &::before {
                content      : '';
                display      : inline-block;
                margin-right : 8px;
                border-top   : 6px solid transparent;
                border-right : 6px solid transparent;
                border-bottom: 6px solid transparent;
                border-left  : 6px solid $conversed-text;

            }

            &.active {
                font-weight: 500;
                color      : $conversed-primary;

                &::before {
                    border-left: 6px solid $conversed-primary;
                }
            }
        }
    }
}
