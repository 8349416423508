@import '../../mixins';
@import '../../variables';

.empty-state-wrapper {
    display        : flex;
    flex-direction : column;
    justify-content: flex-start;
    align-items    : center;
    width          : 100%;

    .cta-wrapper {
        display        : flex;
        flex-direction : column;
        justify-content: center;
        align-items    : center;
        margin         : 72px 0 0;

        > span {
            max-width  : 320px;
            margin     : 0 0 24px;
            text-align : center;
            line-height: 1.2;
            font-size  : 13px;
            font-weight: 400;
        }
    }
}
