@import '../../variables.scss';

.canned-responses-menu {
    position        : absolute;
    background-color: #fff;
    top             : 0;
    z-index         : 10;
    transform       : translateY(-100%);
    width           : 100%;
    max-height      : 180px;
    overflow-y      : scroll;
    border-bottom   : 1px solid $conversed-light-grey;
    box-shadow      : 0 -4px 4px -4px rgba(0,0,0,0.2);

    &::-webkit-scrollbar {
        width: 0;
    }
}

.canned-responses-container {
    display        : flex;
    justify-content: space-between;
    padding        : 5px 24px;
    border-bottom  : 1px solid $conversed-light-grey;
    cursor         : pointer;

    &.active {
        background: $conversed-primary-05;
    }

    &:active, &:focus, &:hover {
        background: $conversed-primary-05;
    }

    p {
        color        : $conversed-text;
        text-overflow: ellipsis;
        max-width    : 70%;
    }

    p:last-child {
        text-align: end;
    }
}
