@import '../../../mixins';
@import '../../../variables';

.node {
    border-radius: 8px;
    background   : #fff;
    border       : 2px solid $conversed-info;
    width        : 100%;
    min-width    : 45px;
    max-width    : 700px;
    min-height   : 60px;

    text-align   : center;

    &-title {
        & > p {
            color: $conversed-info;
        }
        padding-left : 24px;
        padding-right: 24px;
    }

    &-separator {
        width : 100%;
        margin: 0;
        height: 1px;
        color : $conversed-info;
    }

    &-body {
        padding: 2px 24px;
    }
};
