@import "../../mixins";

.wrapper {
    @include displayFlex($display: flex, $direction: row);
    width     : 100%;
    min-height: 100vh;
}

.greeting-text {
    @include displayFlex($display: flex, $direction: row, $justify-content: center, $align-items: center);
}
