@import '../../../scss/variables';
@import '../../mixins';

.settings-form {
    width: 100%;
    @include media-breakpoint-down(xs) {
        padding: 16px;
    }

    .section-category {
        margin-bottom: 24px;
        @include media-breakpoint-down(xs) {
            margin-bottom: 20px;
        }

        h5 {
            margin-bottom: 16px;
            @include media-breakpoint-down(xs) {
                font-size    : 16px;
                margin-bottom: 8px;
            }
        }

        &.form {
            .input-group {
                @include media-breakpoint-down(sm) {
                    display       : flex;
                    flex-direction: column;

                    .input-form {
                        margin-bottom: 16px;
                        @include media-breakpoint-down(xs) {
                            p {
                                padding-bottom: 0;
                            }
                        }
                    }
                }
            }

            p {
                font-size     : 14px;
                padding-bottom: 4px;
                @include media-breakpoint-down(xs) {
                    font-size: 12px;
                }
            }

            .input {
                height: 42px;

                &:focus {
                    box-shadow: none !important;
                    outline   : none !important;
                    border    : 1px solid $conversed-primary-light;
                }
                @include media-breakpoint-down(xs) {
                    height: 40px;
                }
            }
        }

        &.drop-down {
            button {
                height: 32px;
                color : $conversed-gray;

                &:after {
                    display: none;
                }
            }

            .menu {
                margin    : 0;
                padding   : 0;
                border    : none;
                box-shadow: (0px 4px 8px rgba(0, 0, 0, 0.3));

                .item {
                    width      : 180px;
                    height     : 36px;
                    padding    : 10px;
                    border     : none;
                    color      : $conversed-text;
                    display    : flex;
                    align-items: center;

                    &:hover {
                        background: $conversed-primary-05;
                        transition: all .3s ease-in-out;
                    }

                    &:active {
                        background: $conversed-dropdown-active;
                    }

                    &.title {
                        p {
                            font-size  : 14px;
                            font-weight: 500;
                        }
                    }

                    &.list {
                        p {
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        &.check-box {
            label {
                font-size  : 13px;
                font-weight: 400;
            }

            .form-check {
                display    : flex;
                align-items: center;

                input {
                    margin-top  : 0;
                    margin-right: 8px;
                }
            }
        }

        &.table {
            box-shadow: 0 0 2px #B3B3B3;

            tbody, thead {
                td, th {
                    border: none;

                }
            }

            thead {
                border    : none;
                background: $conversed-primary-03;

                th {
                    min-width: 160px;
                }

                p {
                    font-size  : 13px;
                    font-weight: 700;
                }
            }

            tbody {
                p {
                    font-size: 12px;
                }
            }
        }

        &.button-save {
            button {
                height: 40px;
            }

            span {
                font-size  : 13px;
                font-weight: 500;
            }
        }
    }
}
