@import '../../../mixins';
@import '../../../variables';

.chat-panel-sidebar {
    min-width : 214px;
    max-width : 214px;
    height    : calc(100vh - 115px);
    padding   : 30px 32px 30px 16px;
    overflow-y: scroll;
    background: $conversed-white;
    /* width */
    &::-webkit-scrollbar {
        width: 0;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background   : transparent;
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: transparent;
    }
    @include media-breakpoint-down(lg) {
        position   : absolute;
        top        : 115px;
        right      : 0;
        padding    : 12px 16px;
        border-left: 1px solid rgba(0,0,0,0.1);
        z-index    : 5;
    }
    @include media-breakpoint-down(sm) {
        top      : 60px;
        min-width: 100vw;
        height   : calc(100vh - 60px);
        padding  : 12px 25px 12px 16px;
    }

    > div {
        margin-bottom: 32px;
    }

    .client {
        display    : flex;
        align-items: center;

        .icon-back {
            @include hover-state();
            background: none;
            border    : none;
            display   : none;
            padding   : 0;
            @include media-breakpoint-down(sm) {
                display: block;
            }

            span {
                font-size: 20px;
                color    : $conversed-text;
            }
        }

        img {
            width : 30px;
            height: auto;
            margin: 0 12px 0 0;
            @include media-breakpoint-down(sm) {
                margin: 0 8px 0 0;
            }
        }

        div {
            display       : flex;
            flex-direction: column;

            > p {
                line-height: 1.5;
                white-space: nowrap;
            }

            > span {
                @include media-breakpoint-down(sm) {
                    display: none;
                }
                font-size  : 10px;
                font-weight: 400;
                line-height: 1.2;
                color      : $conversed-primary;
            }
        }
    }

    .info {
        > div {
            display    : flex;
            align-items: center;

            &:not(:last-child) {
                margin-bottom: 4px;
            }

            span {
                font-size  : 14px;
                font-weight: 400;
                line-height: 1.5;

                &:not(:last-child) {
                    margin-right: 8px;
                }
            }

        }
    }

    .conversation-details {
        p {
            font-size  : 14px;
            font-weight: 400;
            line-height: 1.5;
        }
    }

    .cv-dropdowns {
        margin-bottom: 8px;

        > span {
            font-size  : 13px;
            font-weight: 400;
            line-height: 1.2;
        }
    }

    .cv-dropdown {
        > button {
            display        : flex;
            align-items    : center;
            justify-content: space-between;
            width          : 100%;
            padding        : 8px 12px 7px;
            font-size      : 13px;
            font-weight    : 500;
            line-height    : 1.2;
            color          : $conversed-gray;
            border         : 1px solid $conversed-gray;
            border-radius  : 2px;
            background     : none;

            &::after {
                display       : inline-block;
                margin-left   : .255em;
                vertical-align: .255em;
                content       : "";
                border-top    : .4em solid;
                border-right  : .4em solid transparent;
                border-bottom : 0;
                border-left   : .4em solid transparent;
            }
        }

        a {
            padding  : 4px 10px;
            font-size: 13px;

            &:active, &:hover {
                background-color: $conversed-primary-05;
                color           : $conversed-text;
            }
        }

        > div {
            transform         : translate(0, 34px) !important;
            width             : 100%;
            padding           : 0;
            border            : none;
            border-radius     : 0;
            -webkit-box-shadow: 0 4px 8px 0 rgba(0,0,0,0.3);
            -moz-box-shadow   : 0 4px 8px 0 rgba(0,0,0,0.3);
            box-shadow        : 0 4px 8px 0 rgba(0,0,0,0.3);

        }

        .active {
            background-color: $conversed-dropdown-active;
        }
    }

    .conversation-labels, .conversation-placeholder {
        display        : flex;
        flex-direction : column;
        justify-content: flex-start;
        align-items    : flex-start;

        > div {
            display        : flex;
            justify-content: flex-start;
            align-items    : center;

            span {
                &:first-child {
                    margin-right: 8px;
                    font-size   : 12px;
                }
            }
        }

        p {
            margin-bottom: 2px;
            font-size    : 14px;
            font-weight  : 400;
            line-height  : 1.5;
        }

        > span {
            margin-left: 21px;
            font-size  : 11px;
            font-weight: 400;
            line-height: 1.2;
        }
    }
}

.cv-label {
    padding    : 0 4px !important;
    font-size  : 10px !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    background : $conversed-danger;

    > span.close-button {
        margin-left: 4px;
        color      : $conversed-light-grey;
        cursor     : pointer;
    }
}

.cv-add-label {
    position   : relative;
    padding    : 0 4px !important;
    font-size  : 10px !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    background : $conversed-gray;
    cursor     : pointer;

    > span {
        color: $conversed-light-grey;

    }
}

.label {
    > span {
        margin-right: 8px;
    }
}
