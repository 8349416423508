@import '../../../mixins';
@import '../../../variables';

.panel-header {
    padding      : 12px 25px 12px 16px;
    background   : $conversed-white;
    display      : flex;
    align-items  : center;
    border-left  : 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    @include media-breakpoint-down(sm) {
        position: absolute;
        top     : 60px;
        right   : 0;
        width   : 100vw;
        height  : 51px;
    }

    .client {
        display    : flex;
        align-items: center;
        @media only screen and (max-width: 1100px) {
            p {
                max-width    : 280px;
                white-space  : nowrap;
                overflow     : hidden;
                text-overflow: ellipsis;

                .app-bar-open & {
                    max-width : 80px;
                    transition: all .6s;
                    @media only screen and (max-width: 1024px) {
                        max-width: 100%;
                    }
                }
            }
        }

        .user-avatar {
            width       : 30px;
            height      : auto;
            margin-right: 8px;
        }

        .arrow-back {
            display: none;
            @include media-breakpoint-down(sm) {
                display: inline-block;
            }
        }
    }

    .wrapper {
        display    : flex;
        align-items: center;

        .segmented-control {
            display     : flex;
            margin-right: 8px;

            .button-default {
                width        : 80px;
                height       : 32px;
                border       : none;
                background   : $conversed-light-grey;
                color        : $conversed-gray;
                cursor       : default;
                border-radius: 2px 0 0 2px;

                p {
                    font-size: 13px;
                    color    : $conversed-gray;
                }

                &.button-open {
                    background   : $conversed-secondary;
                    border-radius: 2px 0 0 2px;
                    cursor       : pointer;

                    p {
                        font-size: 13px;
                        color    : $conversed-white;
                    }
                }
            }

            .button-resolve {
                background     : $conversed-success;
                border-radius  : 0 2px 2px 0;
                display        : flex;
                align-items    : center;
                justify-content: center;
                cursor         : pointer;

                span {
                    color       : $conversed-white;
                    font-size   : 14px;
                    margin-right: 2px;
                }

                p {
                    font-size: 13px;
                    color    : $conversed-white;
                }
            }
        }

        span {
            color: $conversed-gray;
        }
        @media only screen and (max-width: 1024px) {
            display: none;
        }

    }

    .more-vert {
        display: none;
        @media only screen and (max-width: 1024px) {
            display: inline-block;
        }
    }
}
