@import '../../../mixins';
@import '../../../variables';

.cv-profile-dropdown {
    position          : absolute;
    top               : 42px;
    right             : -12px;
    min-width         : 240px;
    padding           : 0 12px;
    background-color  : $conversed-white;
    -webkit-box-shadow: 0 4px 8px 0 rgba(0,0,0,0.3);
    -moz-box-shadow   : 0 4px 8px 0 rgba(0,0,0,0.3);
    box-shadow        : 0 4px 8px 0 rgba(0,0,0,0.3);

    .cv-user {
        display    : flex;
        align-items: center;
    }

    .user-name-type {
        display       : flex;
        flex-direction: column;
        font-size     : 16px;
        line-height   : 1.2;
        font-weight   : 500;
        color         : $conversed-text;

        span {
            font-size  : 11px;
            font-weight: 400;
            color      : $conversed-primary;
        }
    }

    .user-avatar {
        max-width   : 45px;
        margin-right: 12px;
    }

    span {
        font-size  : 13px;
        line-height: 1.2;
        color      : $conversed-text;
    }

    .wrapper-top {
        position: relative;
        padding : 12px 0 0;

        &::after {
            content         : "";
            position        : absolute;
            right           : -8px;
            bottom          : 0;
            left            : -8px;
            background-color: #F0F0F0;
            height          : 1px;
        }

        .cv-status {
            position   : relative;
            margin-left: 16px;

            &::before {
                content         : "";
                position        : absolute;
                top             : 50%;
                left            : -16px;
                transform       : translateY(-50%);
                width           : 8px;
                height          : 8px;
                border-radius   : 50%;
                background-color: green;
            }
        }
    }

    .wrapper-middle {
        padding: 10px 0;

        > div {
            display    : flex;
            align-items: center;

            span:first-child {
                margin-right: 8px;
                font-size   : 18px;
            }

            span:nth-child(3) {
                margin-left: auto;
                font-size  : 18px;
            }

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }

    .wrapper-bottom {
        position: relative;
        padding : 10px 0;

        a {
            display        : flex;
            align-items    : center;
            text-decoration: none;

            span:first-child {
                margin-right: 8px;
                font-size   : 18px;
            }

        }

        &::after {
            content         : "";
            position        : absolute;
            right           : -12px;
            top             : 0;
            left            : -12px;
            background-color: #F0F0F0;
            height          : 1px;
        }
    }
}
