.wrapper {
    display: flex;
    height : calc(100vh - 60px);
}

.settings-content-wrapper {
    width     : 100%;
    height    : calc(100vh - 60px);
    padding   : 40px 30px 10px;
    overflow-y: scroll;
}
