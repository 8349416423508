@import '../../../../variables';

.message-in, .message-out {
    max-width: 60%;

    p {
        font-size  : 14px;
        font-weight: 400;
        line-height: 150%;
    }
}

.message-in {
    // background   : $conversed-light-grey;
    border-radius: 0 8px 8px 8px;
    align-self   : flex-start;

    &-text {
        background: pink;
    }
}

.message-out {
    align-self: flex-end;
    margin    : 2px 16px;

    &-text {
        background   : $conversed-light-blue;
        border-radius: 8px 0 8px 8px;
        padding      : 8px 12px;
        box-shadow   : inset 0 -1px 0 rgba(0, 0, 0, 0.1);
    }

    .button-pillar {
        display   : flex;
        margin-top: 8px;

        .pillar {
            padding      : 8px;
            border-radius: 8px;
            border       : 1px solid $conversed-primary;
            margin-right : 8px;
            margin-left  : 8px;

            &:not(:first-child) {
                margin: 0;
            }

            p {
                color: $conversed-primary;
            }
        }
    }
}
