/* Base Elements */
.table, a, body, h1, h2, h3, html, label, ol, p, table, ul {
    margin : 0;
    padding: 0;
}

body {
    @include font($family: $font-main, $weight: 500, $color: $conversed-text, $line-height: 28px);

    * {
        box-sizing: border-box;
    }
    overflow: hidden;
}

button.btn {
    @include displayFlex($display: flex, $direction: row, $justify-content: center, $align-items: center);
    // @include padding($full: 0px 24px);
    @include borderBox($border: 1px solid $conversed-white, $radius: 2px);

    &.btn-primary {
        /* primary buttons */
        @include blur($background-color: $conversed-primary);
        max-height: 30px;
        font-size : 13px;
    }

    &.btn-secondary {
        /* secondary buttons */
        @include blur($background-color: $conversed-secondary);
    }

    &.btn-stroke {
        padding      : 0 6px 0 12px;
        height       : 32px;
        border-radius: 2px;
        border       : 1px solid $conversed-gray;
        background   : $conversed-white !important;

        p {
            font-size  : 13px;
            font-weight: 500;
            color      : $conversed-gray;
        }

        > :not(:last-child()) {
            margin-right: 4px;
        }

        &:focus {
            box-shadow: none !important;
            outline   : none !important;
            border    : 1px solid $conversed-gray !important;
        }
    }

}

.general-add-button {
    display    : flex;
    align-items: center;
    border     : none;
    background : none;

    &:hover {
        .icon {
            box-shadow: 0 0 18px rgba(0, 0, 0, 0.2);

            span {
                color: $conversed-primary;
            }
        }

        .label {
            color: $conversed-primary;
        }
    }

    &.with-text {
        margin: 0 0 24px;

        .label {
            display: block;
        }
    }

    .icon {
        height       : 50px;
        border-radius: 50%;
        padding      : 0;
        background   : $conversed-primary-02;
        transition   : all .5s;
        box-shadow   : 0 0 4px rgba(0, 0, 0, 0.3);

        span {
            font-size : 50px;
            color     : $conversed-gray;
            transition: all .5s;
        }
    }

    .label {
        display   : none;
        margin    : 0 0 0 16px;
        transition: all .5s;
        font-size : 19px;
        color     : $conversed-text;
    }
}

h1, h2, h3, h5, h5, p {
    padding: 0;
    margin : 0;
}

h1 {
    @extend .heading-fontsize;
    @include font($size: 40px);
}

h2 {
    @extend .heading-fontsize;
    @include font($size: 33px);
}

h3 {
    @extend .heading-fontsize;
    @include font($size: 28px);
}

h4 {
    @extend .heading-fontsize;
    @include font($size: 23px);
}

h5 {
    @extend .heading-fontsize;
    @include font($size: 19px);
}

p {
    @extend .heading-fontsize;
    @include font($size: 16px, $font-style: normal, $weight: 400);
}

.conversed-checkbox {
    @include conversed-checkboxes-radios(18px, 19px, 1px, 0, 30px, 0px);

    line-height: 22px;
    display    : flex;
    padding    : 8px 0;
}

img.circle {
    border-radius: 50%;
}

// Needed for spinner element to be on top of other ui elements on loading
.global {
    z-index: 10000 !important;
}

.clickable {
    cursor: pointer;
}
