@import '../../../mixins';
@import '../../../variables';

.button-inbox {
    button {
        margin-right: 16px;

        &:after {
            display: none;
        }
    }

    .menu {
        margin    : 0;
        padding   : 0;
        border    : none;
        box-shadow: (0px 4px 8px rgba(0, 0, 0, 0.3));

        .item {
            width      : 220px;
            height     : 36px;
            padding    : 10px;
            border     : none;
            color      : $conversed-text;
            display    : flex;
            align-items: center;

            &:hover {
                background: $conversed-primary-05;
                transition: all .3s ease-in-out;
            }

            &:active {
                background: $conversed-dropdown-active;
            }

            &.title {
                p {
                    font-size  : 14px;
                    font-weight: 500;
                }
            }

            &.list {
                p {
                    font-size: 14px;
                }
            }
        }

        .divider {
            margin: 0;
        }
    }
}
