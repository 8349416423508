@import '../../../mixins';
@import '../../../variables';

.node {
    & > p {
        color: $conversed-info;
    }
    border       : 2px solid $conversed-info;
    border-radius: 8px;
    text-align   : center;
    padding      : 8px;
    margin       : 4px;
    min-width    : 250px;
    cursor       : pointer;

    &-container {
        & > p {
            color     : $conversed-info;
            margin    : 4px;
            text-align: center;
        }
        padding-left : 24px;
        padding-right: 24px;
        box-shadow   : inset 1px 0 0 rgba(0, 0, 0, 0.1);
    }
};
