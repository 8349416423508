@import '../variables';
@import '../mixins';

.modal-inbox {
    @include media-breakpoint-down(sm) {
        p {
            font-size: 14px;
        }
    }

    .modal-header {
        border       : none;
        margin-bottom: 24px;
        @include media-breakpoint-down(sm) {
            h3 {
                font-size: 23px;
            }
        }
    }

    h3 {
        color: $conversed-primary;
    }

    > div {
        max-width: 640px;
        height   : auto;
    }

    > div > div {
        border       : none;
        padding      : 24px;
        margin-bottom: 16px;
        @include media-breakpoint-down(sm) {
            padding: 16px;
            margin : 16px;
        }
        @include media-breakpoint-down(xs) {
            margin: 0;
        }
    }

    > div > div > div {
        padding: 0;
    }
}
