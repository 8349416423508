@import '../../../mixins';
@import '../../../variables';

.button-vert-more {
    button {
        border: none !important;

        &:focus {
            box-shadow: none !important;
            outline   : none !important;
        }
    }
    display: none;
    @media only screen and (max-width: 1024px) {
        display: inline-block;
    }

    .dropdown {
        padding   : 0;
        color     : $conversed-gray;
        background: none !important;

        &:after {
            display: none;
        }

        .icon-vert-more {
            color: $conversed-text;
        }
    }

    .menu {
        margin    : 0;
        padding   : 0;
        border    : none;
        box-shadow: (0px 4px 8px rgba(0, 0, 0, 0.3));
        min-width : 140px;

        .item {
            height     : 36px;
            padding    : 10px;
            border     : none;
            color      : $conversed-text;
            display    : flex;
            align-items: center;

            &.inbox {
                display        : flex;
                justify-content: space-between;

                span {
                    font-size: 14px;
                }
            }

            &.button-resolve {
                background: $conversed-success;

                p {
                    color: $conversed-white;
                }

                &:hover {
                    background: $conversed-success;
                }
            }

            &.button-open {
                background: $conversed-secondary;

                p {
                    color: $conversed-white;
                }

                &:hover {
                    background: $conversed-secondary;
                }
            }

            &:hover {
                background: $conversed-primary-05;
                transition: all .3s ease-in-out;
            }

            &:active {
                background: $conversed-dropdown-active;
            }

            &.title {
                p {
                    font-size  : 14px;
                    font-weight: 500;
                }
            }

            &.list {
                p {
                    font-size: 14px;
                }
            }
        }

        .divider {
            margin: 0;
        }
    }
}
