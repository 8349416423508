@import '../../variables';
@import '../../mixins';

.modal-section {
    margin-bottom: 24px;
    @include media-breakpoint-down(sm) {
        margin-bottom: 16px;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &.modal-input {
        h5 {
            margin-bottom: 16px;
        }

        .input-form {
            margin-bottom: 16px;
            @include media-breakpoint-down(sm) {
                p {
                    padding-bottom: 0;
                }
            }
        }

        p {
            font-size     : 14px;
            padding-bottom: 4px;
            @include media-breakpoint-down(sm) {
                font-size: 12px;
            }
        }

        .input {
            height: 42px;

            &:focus {
                box-shadow: none !important;
                outline   : none !important;
                border    : 1px solid $conversed-primary-light;
            }
            @include media-breakpoint-down(sm) {
                height: 40px;
            }
        }
    }

    &.modal-drop-down {
        .column {
            padding: 0;
            @include media-breakpoint-down(sm) {
                margin-bottom: 24px;
            }
        }

        .drop-down {
            &.widget {
                .menu {
                    min-width: 60px;

                    .item {
                        width: 60px;
                    }
                }
            }

            button {
                .color {
                    width     : 14px;
                    height    : 14px;
                    background: $conversed-secondary;
                }
            }
        }

        h5 {
            margin-bottom: 16px;
        }

        button {
            height: 32px;
            color : $conversed-gray;

            &:after {
                display: none;
            }
        }

        .menu {
            margin    : 0;
            padding   : 0;
            border    : none;
            box-shadow: (0px 4px 8px rgba(0, 0, 0, 0.3));

            .item {
                width      : 180px;
                height     : 36px;
                padding    : 10px;
                border     : none;
                color      : $conversed-text;
                display    : flex;
                align-items: center;

                &:hover {
                    background: $conversed-primary-05;
                    transition: all .3s ease-in-out;
                }

                &:active {
                    background: $conversed-dropdown-active;
                }

                &.title {
                    p {
                        font-size  : 14px;
                        font-weight: 500;
                    }
                }

                &.list {
                    p {
                        font-size: 14px;
                    }
                }
            }

            .color {
                width     : 14px;
                height    : 14px;
                background: $conversed-secondary;
            }
        }
    }
}

.footer {
    > div {
        border : none;
        padding: 0;
        margin : 0;

        button {
            margin    : 0;
            min-height: 42px;
            @include media-breakpoint-down(sm) {
                min-height: 40px;
            }
        }
    }
}
